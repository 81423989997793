import * as React from "react";
import { useState } from "react";
import { graphql, HeadFC } from "gatsby";
import { Link } from "gatsby";
import Layout from "../components/layouts/Default/Default";
import { ResponsiveBlock } from "../components/layouts/ResponsiveBlock/ResponsiveBlock";
import { SchoolTopMainImage } from "../components/elements/MainImage/MainImage";
import SnsShare from "../components/elements/SnsShare/SnsShare";
import { SchoolTopContentList } from "../components/elements/SchoolTopContentList/SchoolTopContentList";
import H2 from "../components/elements/H2/H2";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import Hr from "../components/elements/Hr/Hr";
import { CommonHead } from "../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  // console.log({ data });
  // const lang = useLang();
  // console.log({ lang });

  const title = data.allSettingsJson?.nodes[0].siteTitle;

  return (
    <Layout title={title} pageType="TopPage" displayBottomNav={false}>
      <div className="tw-c-page-top md:tw-c-page-top-md">
        <SchoolTopMainImage></SchoolTopMainImage>
        <nav>
          <ResponsiveBlock bgColor="">
            <div className="tw-mt-8 md:tw-mt-12 tw-mb-8 md:tw-mb-14">
              <h2 className="tw-text-center tw-font-ascii tw-text-3xl md:tw-text-4xl">
                Special
              </h2>
              <p className="tw-mx-4 md:tw-mx-0 tw-mt-4 md:tw-mt-12 tw-text-sm md:tw-text-2xl">
                日本外国語専門学校は、11学科36専攻の総合学園です。個性あふれる学科・専攻の魅力を深掘りします。
              </p>
            </div>
          </ResponsiveBlock>
          <SchoolTopContentList></SchoolTopContentList>
        </nav>
        <nav className="tw-mx-4 tw-my-8 md:tw-mx-0">
          <ResponsiveBlock bgColor="">
            <H2 title="その他の学科" />
            <ul className="tw-mx-6 tw-mt-9 md:tw-mt-9 tw-mb-3 md:tw-mb-4">
              <li className="tw-flex tw-mb-6">
                <div className="tw-mr-4">
                  <BuildingOffice2Icon className="tw-w-6 md:tw-w-8 tw-h-6 md:tw-h-8" />
                </div>
                <div className="tw-text-base md:tw-text-xl">
                  <a
                    href={`https://www.jcfl.ac.jp/course/index.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-underline"
                  >
                    その他の学科をご確認する場合はこちらをご覧ください。
                  </a>
                </div>
              </li>
            </ul>
          </ResponsiveBlock>
          <Hr />
          <SnsShare path="/" title={title} spaceBottom />
        </nav>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={data.allSettingsJson?.nodes[0].siteTitle}
    ogImageFilepath="/images/top/TOP1.jpg"
  >
    <title>{data.allSettingsJson?.nodes[0].siteTitle}</title>
  </CommonHead>
);
