import { Link } from "gatsby";
import * as React from "react";
import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";
import PageHeader from "../PageHeader/PageHeader";
import PageImage from "../PageImage/PageImage";

/**
 * コンテンツリスト
 *
 * @param param0
 * @returns
 */
export const SchoolTopContentList = ({}) => {
  return (
    <div className="tw-c-school-top-content-list md:tw-c-school-top-content-list-md">
      <ul>
        <li>
          <Content
            gakka="国際エアライン科"
            color="gakka1"
            title="あなたの知らないエアライン業界"
            description="エアライン業界に3000名以上のプロを生んだ学科が、キャビンアテンダントとグランドスタッフのリアルな姿を伝えます。"
            link="/flight/"
            imagesrc="/images/flight/AL-TOP1-1.jpg"
            sentence1="飛行機の中や空港で"
            sentence2="起きているリアル"
          ></Content>
        </li>
        <li>
          <Content
            gakka="総合英語科"
            color="gakka2"
            title="英語でつながる仲間と未来"
            description="授業・海外研修・インターンシップを仲間と楽しむ。英語がわかる、マナーが身につく。"
            link="/comprehensive/"
            imagesrc="/images/comprehensive/CE-TOP1.jpg"
            sentence1="今を楽しむ"
            sentence2="それが私の力になる"
          ></Content>
        </li>
        <li>
          <Content
            gakka="韓国語＋英語専攻"
            gakkaSub="アジア・ヨーロッパ言語科"
            color="gakka3"
            title="大好きな韓国を多くの人へ"
            description="韓国が好き、韓国語が好き、Kpopが好き、韓国文化が好き。そこがすべての始まり。"
            link="/korean/"
            imagesrc="/images/korean/KO-sougouTOP.jpg"
            sentence1="韓国語から"
            sentence2="ひろがる世界"
          ></Content>
        </li>
      </ul>
    </div>
  );
};

interface ContentProps {
  gakka: string;
  gakkaSub: string;
  color: string;
  title: string;
  description: string;
  link: string;
  imagesrc: string;
  sentence1: string;
  sentence2: string;
}

/**
 * コンテンツボックス
 *
 * @param param0
 * @returns
 */
const Content = ({
  gakka,
  gakkaSub = "",
  color,
  title,
  description,
  link,
  imagesrc,
  sentence1,
  sentence2,
}: ContentProps) => {
  return (
    <div className="tw-c-content-flight md:tw-c-content-flight-md">
      <Link to={link} className="tw-block">
        <ResponsiveBlock bgColor={`tw-bg-${color}`}>
          <div
            className={`tw-px-4 tw-pt-4 md:tw-pt-8 tw-pb-6 md:tw-pb-3 tw-text-baseWhite tw-relative`}
          >
            <p className="tw-text-xs md:tw-text-base">{gakkaSub}</p>
            <p className="tw-text-sm md:tw-text-xl tw-font-bold">
              {gakka}
              <span className="tw-font-normal tw-text-xss md:tw-text-sm">
                より
              </span>
            </p>
            <h3 className="tw-mt-2 tw-pl-3 tw-border-l-4 tw-border-baseWhite tw-text-lg md:tw-text-3xl tw-font-black">
              {title}
            </h3>
            <p className="tw-mt-2 tw-text-xs md:tw-text-base tw-opacity-90">
              {description}
            </p>
            <div className="tw-flex tw-justify-end tw-mt-2 md:tw-absolute md:tw-bottom-4 md:tw-right-0">
              <span
                className={`tw-px-8 tw-py-1 tw-bg-baseWhite tw-border tw-border-${color} tw-rounded-full tw-text-${color} tw-text-xss md:tw-text-base tw-font-bold`}
              >
                MORE
              </span>
            </div>
          </div>
          <div className={`tw-border-l-8 tw-border-${color}`}>
            <div className="tw-relative">
              <img
                src={imagesrc}
                alt={`${sentence1} ${sentence2}`}
                className="tw-w-full"
              />
              <p className="tw-absolute tw-left-4 md:tw-left-16 tw-bottom-4 md:tw-bottom-16 tw-text-baseWhite tw-text-lg md:tw-text-3xl tw-font-black">
                <span
                  className={`tw-inline-block tw-px-2 tw-pt-1 tw-bg-${color}`}
                >
                  {sentence1}
                </span>

                <br />
                <span
                  className={`tw-inline-block tw-px-2 tw-pb-1 tw-bg-${color}`}
                >
                  {sentence2}
                </span>
              </p>
            </div>
          </div>
        </ResponsiveBlock>
      </Link>
    </div>
  );
};

/**
 * 総合英語科コンテンツへ
 *
 * @param param0
 * @returns
 */
const ContentComprehensive = ({}) => {
  return (
    <div className="tw-c-content-comprehensive md:tw-c-content-comprehensive-md">
      <Link to="/comprehensive/">総合英語科</Link>
    </div>
  );
};
