import React from "react";

interface Props {}

/**
 * SSRをさせない場合のラッピングコンポーネント
 *
 * @param param0
 * @returns
 */
export const NoSSR = ({ children }: React.PropsWithChildren<Props>) => {
  return (
    <>
      {typeof document !== "undefined" ? (
        children
      ) : null}
    </>
  );
};
