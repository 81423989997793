import * as React from "react";
import { useMediaQuery } from "react-responsive";
import style from "../../../configs/style";
import { NoSSR } from "../../commons/NoSSR/NoSSR";
import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";

/**
 * 学校TOPのメインイメージ
 */
export const SchoolTopMainImage = ({}) => {
  const isSP = useMediaQuery({ query: style.StyleSettings.SP_AND_TABLET });
  return (
    <div className="tw-c-top-main-image md:tw-c-top-main-image-md tw-relative">
      <div className="tw-c-top-main-image-area tw-bg-baseWhite tw-flex tw-justify-center">
        <NoSSR>
          <img
            src={isSP ? "/images/top/TOP1-SP.jpg" : "/images/top/TOP1.jpg"}
            alt=""
          />
        </NoSSR>
      </div>
      <ResponsiveBlock bgColor="">
        <span className="tw-c-top-main-image-copy-l1 tw-absolute tw-font-black tw-text-white tw-leading-6 md:tw-leading-none tw-inline-block tw-bg-baseMainColor">
          世界のとびらをひらく
        </span>
        <span className="tw-c-top-main-image-copy-l2 tw-absolute tw-font-black tw-text-white tw-leading-6 md:tw-leading-none tw-inline-block tw-bg-baseMainColor">
          わたしの未来を描く
        </span>
      </ResponsiveBlock>
    </div>
  );
};
